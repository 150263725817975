<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="12" :sm="24" :xs="24">
          <el-row justify="start">
            <h4>Pedidos em Produção</h4>
          </el-row>
        </el-col>
        <el-dropdown
          @command="(c) => filterOrders(c)"
          size="medium"
          trigger="click"
        >
          <el-button type="primary" size="medium">
            Exibir por: {{ displayPage || ""
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="Praça">
                <li
                  :class="{
                    'el-dropdown-menu__item': true,
                    'no-padding': true,
                    active: displayPage === 'Praça',
                  }"
                >
                  Praça
                </li>
              </el-dropdown-item>
              <el-dropdown-item command="Licitação"
                ><li
                  :class="{
                    'el-dropdown-menu__item': true,
                    'no-padding': true,
                    active: displayPage === 'Licitação',
                  }"
                >
                  Licitação
                </li></el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-row>
    </template>
    <el-empty
      v-if="!Object.keys(GroupedOrders)?.length"
      description="Nenhum pedido nesta lista"
    ></el-empty>
    <div v-else>
      <el-descriptions :column="5" border v-loading="!OrderStats">
        <el-descriptions-item label="Em dia:">{{
          OrderStats?.on_time
        }}</el-descriptions-item>
        <el-descriptions-item label="A vencer:">{{
          OrderStats?.last_day
        }}</el-descriptions-item>
        <el-descriptions-item label="Atrasado:">
          {{ OrderStats?.delayed }}
        </el-descriptions-item>
        <el-descriptions-item label="Produzindo:">
          {{ OrderStats?.producing }}
        </el-descriptions-item>
        <el-descriptions-item label="Total:">{{
          OrderStats?.total
        }}</el-descriptions-item>
      </el-descriptions>

      <div v-for="(orders, route) in GroupedOrders" :key="route">
        <h2 class="el-table__subheader_name">{{ route }}</h2>

        <el-table
          stripe
          :cell-style="() => 'text-align:center;'"
          :data="orders"
          style="width: 100%; z-index: 0"
          :row-style="() => 'cursor: pointer'"
          :span-method="spanDateRow"
          :row-class-name="
            ({ row: order }) => ({
              'row-was-updated': orderWasUpdated(order),
              'row-is-delayed': orderIsDelayed(order),
            })
          "
          @row-click="(r) => openOrderModal(r)"
        >
          <el-table-column label="#" prop="code" width="100">
            <template #default="{ row: order }">
              <span
                v-if="order.type === 'date'"
                class="el-table__subheader_date"
              >
                {{ order.date }}</span
              >
              <span v-else>
                {{ order.code }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="romaneio"
            prop="invoice_id"
            width="100"
          ></el-table-column>
          <el-table-column prop="seller.name" label="vendedor">
          </el-table-column>
          <el-table-column
            prop="client.name"
            label="razão social"
          ></el-table-column>
          <el-table-column prop="client.name" label="cliente">
            <template #default="{ row: order }">
              {{ order?.client?.legal?.social_name || order?.client?.name }}
            </template>
          </el-table-column>
          <el-table-column
            label="data pedido"
            width="100"
            :formatter="(r) => formatDate(r.created_at)"
          >
          </el-table-column>
          <el-table-column
            label="data entrega"
            width="100"
            :formatter="(r) => formatDate(r.deliver_at)"
          >
          </el-table-column>
          <el-table-column label="estado" prop="status_formatted" width="100">
            <template #default="s">
              <el-tag
                :type="getStatusType(s.row)"
                v-if="s.row.type !== 'date'"
                >{{ s.row.status_formatted }}</el-tag
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <order-modal
      :order="order"
      :showModal="showOrderModal"
      @should-update="fetchOrders"
      @close-modal="showOrderModal = false"
    ></order-modal>
  </el-card>
</template>

<script>
//import { ElNotification } from "element-plus";
import OrderModal from "./modals/OrderModal.vue";
import OrderService from "../services/orders";
import { hasOnlyDigits } from "../utils/functions";
import { currencyFormatter, dateFormatter } from "../utils/formatters";
export default {
  name: "BillsPage",
  components: { OrderModal },
  data: () => ({
    hasError: false,
    order: null,
    showOrderModal: false,
    isLoading: true,
    displayPage: "Praça",
    orders: null,
    currencyFormatter,
    dateFormatter,
    dateFormatterLong: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "long",
    }),
    fetchRepeater: null,
  }),
  mounted() {
    this.fetchOrders();
    this.fetchRepeater = setInterval(() => this.fetchOrders(), 5000);
  },
  watch: {},
  unmounted() {
    clearInterval(this.fetchRepeater);
  },
  computed: {
    Orders() {
      return this.orders || [];
    },
    GroupedOrders() {
      return this.groupByDeliverDate(this.groupByClientRoute()) || {};
    },
    SearchName() {
      return this.$store.state.searchNameQuery;
    },
    OrderStats() {
      if (this.Orders?.length) {
        const delayed = this.Orders.filter((o) => this.isDelayed(o))?.length;
        const on_time = this.Orders.length - delayed;
        const producing = this.Orders.filter(
          (o) => o.status === "production"
        )?.length;
        const last_day = this.Orders.filter(
          (o) => !this.isDelayed(o) && this.hoursLeft(o.deliver_at) > 18
        )?.length;

        return {
          delayed,
          on_time,
          producing,
          last_day,
          total: this.Orders.length,
        };
      }

      return null;
    },
  },
  methods: {
    orderIsDelayed(order) {
      const now = new Date();
      now.setHours(0);

      return order.deliver_at < now.toISOString();
    },
    orderWasUpdated(order) {
      if (order.status === "production") return true;

      if (this.anyProductWasUpdated(order.products)) return true;
    },
    anyProductWasUpdated(products) {
      return products?.some((p) => !!Number(p.weight));
    },
    spanDateRow({ row: order, columnIndex }) {
      if (order.type === "date" && columnIndex === 0) return [1, 4];
    },
    groupByDeliverDate(groupedOrders) {
      const groupedByDeliver = {};

      if (groupedOrders) {
        Object.entries(groupedOrders).forEach(([driver, orders]) => {
          groupedByDeliver[driver] = orders;

          if (Array.isArray(orders)) {
            const dates = orders.reduce((t, o) => {
              t[this.formatDate(o.deliver_at)] = true;
              return t;
            }, {});

            Object.keys(dates).forEach((date) => {
              const dateIndex = orders.findIndex(
                (o) => this.formatDate(o.deliver_at) === date
              );
              if (dateIndex >= 0) {
                orders.splice(dateIndex, 0, {
                  type: "date",
                  date,
                });
              }
            });
          }
        });
      }

      return groupedByDeliver;
    },
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    hoursLeft(time) {
      return (new Date(time) - new Date()) / 36e5;
    },
    openOrderModal(order) {
      this.order = order;
      this.showOrderModal = true;
    },
    formatDate(c) {
      if (new Date(`${c}`)?.getTime())
        return this.dateFormatter.format(new Date(c));
      else return "";
    },
    groupByClientRoute() {
      const groupedOrders = this.orders?.reduce((t, o) => {
        const route = o.client?.route?.name || "SEM ROTA";
        if (t[route]) t[route].push(o);
        else t[route] = [o];

        return t;
      }, {});

      return groupedOrders;
    },

    isDelayed(order) {
      return order.deliver_at < new Date().toISOString();
    },
    getStatusType(order) {
      if (
        this.isDelayed(order) &&
        ["created", "accepted", "production", "delayed"].includes(order.status)
      )
        order.status = "delayed";
      return ORDER_STATUS_TYPES[order.status];
    },
    filterOrders(filter) {
      this.displayPage = filter;
      this.fetchOrders();
    },
    async fetchOrders() {
      const { orders } = await OrderService().index({
        status: "created,production,accepted",
        searchName: (!hasOnlyDigits(this.SearchName) && this.SearchName) || "",
        searchCpfCnpj:
          (hasOnlyDigits(this.SearchName) && this.SearchName) || "",
        orderByKey: "deliver_at",
        orderByMethod: "asc",
        isPublicSell: this.displayPage === "Licitação",
      });

      if (orders) this.orders = orders;

      this.isLoading = false;
    },
  },
};
const ORDER_STATUS_TYPES = {
  created: "info",
  production: null,
  analisys: "warning",
  accepted: null,
  ready: null,
  delayed: "danger",
  delivered: "success",
  completed: "success",
};
</script>
<style scoped>
.el-table /deep/ tr.row-was-updated {
  background: #8e99f3 !important;
}

.el-table /deep/ tr.row-is-delayed {
  background: #ffa4a2 !important;
}

.el-table /deep/ .row-is-delayed td.el-table__cell {
  background: none !important;
}

.el-table /deep/ .row-was-updated td.el-table__cell {
  background: none !important;
}
</style>
